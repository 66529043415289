<template>
<div>
  <h4>Групповая смена тарифного плана PPP+</h4>
  <div class="form-group">
    <label for="tp-select">Новый тарифный план</label>
    <RadixTariffInput name="tp-select" v-model=tplan class="form-control" />
  </div>

  <br>
  <button class="btn btn-secondary" @click=makeOp >Сменить ТП</button>
</div>
</template>

<script>
  import darsan from "darsan"
  import RadixTariffInput from "common/misc/RadixTariffInput.vue"
  
  export default {
    name: "gang-change-ppp2-tp",
    
    components: {RadixTariffInput},
    
    props: {
      query: String,
    },

    created()
    {
      this.ensure("tplan")
    },
    
    data()
    {
      return {
        tplan: "",
      }
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/ppp/tplan', {
            tp_id: this.tplan,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))

        this.$emit("dismiss")
      }
    },
  }
</script>
