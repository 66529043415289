var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Групповая смена тарифного плана IPoE")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", { attrs: { for: "tp-select" } }, [
          _vm._v("Новый тарифный план")
        ]),
        _vm._v(" "),
        _c("RadixTariffInput", {
          staticClass: "form-control",
          attrs: { name: "tp-select" },
          model: {
            value: _vm.tplan,
            callback: function($$v) {
              _vm.tplan = $$v
            },
            expression: "tplan"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Сменить ТП")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }