var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "alert alert-warning form-group" }, [
    _c("h4", [_vm._v("Оповещение пользователей по телефону")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [_vm._v("Текст")]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notify.message,
              expression: "notify.message"
            }
          ],
          staticClass: "form-control",
          attrs: { disabled: "", readonly: "" },
          domProps: { value: _vm.notify.message },
          on: {
            input: function($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.notify, "message", $event.target.value)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("label", [_vm._v("Сообщение")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.notify,
                expression: "notify"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.notify = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.usefulTemplates, function(item) {
            return _c("option", { domProps: { value: item } }, [
              _vm._v(_vm._s(item.name_ru || item.name))
            ])
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Начать обзвон")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }