var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Групповая смена статуса абонентов")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "fop" } }, [_vm._v("Новый статус:")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.status,
              expression: "status"
            }
          ],
          staticClass: "form-control",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.status = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { attrs: { value: "1" } }, [_vm._v("Активный")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "0" } }, [_vm._v("Отключен")])
        ]
      )
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Изменить статус")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }