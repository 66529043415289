var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Групповая смена устройства IPoE")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", { attrs: { for: "device" } }, [_vm._v("Новое устройство")]),
        _vm._v(" "),
        _c("DeviceInput", {
          attrs: { name: "device" },
          model: {
            value: _vm.device,
            callback: function($$v) {
              _vm.device = $$v
            },
            expression: "device"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Сменить устройство")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }