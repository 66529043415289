<template>
<div>
  <h4>Групповая смена группы абонента</h4>
  <div class="form-group">
    <label for="fop">Новая группа:</label>
    <select class="form-control" v-model="group">
      <option v-for="item in $store.state.preload['/group']" :value=item.entity >{{item.display_name}}</option>
    </select>
  </div>

  <br>
  <button class="btn btn-secondary" @click=makeOp >Изменить группу</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-change-group",
    
    props: {
      query: String,
    },
    
    data()
    {
      return {
        group: "",
      }
    },
    
    created()
    {
      this.ensure("group")
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/client/group', {
            group: this.group,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))

        this.$emit("dismiss")
      }
    },
  }
</script>