<template>
<div>
  <h4>Групповая смена статуса абонентов</h4>
  <div class="form-group">
    <label for="fop">Новый статус:</label>
    <select class="form-control" v-model="status">
      <option value="1">Активный</option>
      <option value="0">Отключен</option>
    </select>
  </div>

  <br>
  <button class="btn btn-secondary" @click=makeOp >Изменить статус</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-change-status",
    
    props: {
      query: String,
    },
    
    data()
    {
      return {
        status: 1,
      }
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/client/status', {
            status: this.status,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))

        this.$emit("dismiss")
      }
    },
  }
</script>