var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Групповая смена типа подключения")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "type-select" } }, [
        _vm._v("Новый тип подключения")
      ]),
      _vm._v(" "),
      _vm.ready
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.conntype,
                  expression: "conntype"
                }
              ],
              staticClass: "form-control",
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.conntype = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(
              _vm.$store.state.preload[
                "/client-tags-connection-type"
              ].oneof.split(","),
              function(item) {
                return _c("option", { domProps: { value: item } }, [
                  _vm._v(_vm._s(item))
                ])
              }
            ),
            0
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Сменить тип")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }