<template>
<div class="alert alert-warning form-group">
    
    <h4>Оповещение пользователей по телефону</h4>

    <div class="row">
        <div class='col-md-12'>
          <label>Текст</label>
	  <textarea class="form-control" disabled readonly v-model="notify.message" ></textarea>
	</div>
    </div>

    <div class="row">
        <div class='col-md-12'>
	<label>Сообщение</label>
        <select class="form-control" v-model="notify" >
          <option v-for="item in usefulTemplates" :value=item>{{item.name_ru || item.name}}</option>
	</select>
	</div>
    </div>
    <br/>

    <button class="btn btn-secondary" @click=makeOp >Начать обзвон</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-call-notify",
    
    props: {
      query: String,
    },
    
    data()
    {
      return {
        notify: "",
      }
    },
    
    created()
    {
      this.ensure("notifications-templates")
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/phone', {
            notify: this.notify.name,
            notify_ru: this.notify.name_ru,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))

        this.$emit("dismiss")
      }
    },
    
    computed: {
      usefulTemplates()
      {
        return this.$store.state.preload['/notifications-templates'].filter(el => el.type!=1)
      }
    },
  }
</script>