var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Отключение снятий из-за проблем с Интернетом")]),
    _vm._v(" "),
    _c("div", { staticClass: "alert alert-warning" }, [
      _vm._v(
        "\n    Все выбранные пользователи будут отмечены как имеющие проблемы с Интернетом.\n    Снятие денег с их счетов будет приостановлено, пока они снова не выйдут в сеть.\n"
      )
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Остановить снятия")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }