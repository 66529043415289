var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Групповой запуск акции")]),
    _vm._v(" "),
    _c("div", { staticClass: "alert alert-warning" }, [
      _vm._v(
        "\n    У всех выбранных пользователей будет запущена новая акция.\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "action-select" } }, [_vm._v("Акция")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.action,
              expression: "action"
            }
          ],
          staticClass: "form-control",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.action = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.$store.state.preload["/action"], function(item) {
          return _c("option", { domProps: { value: item.entity } }, [
            _vm._v(_vm._s(item.display_name))
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Запустить акцию")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }