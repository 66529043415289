var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h4", [_vm._v("Групповое добавление комментария")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "text" } }, [_vm._v("Текст:")]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.text,
            expression: "text"
          }
        ],
        staticClass: "form-control",
        attrs: { id: "text" },
        domProps: { value: _vm.text },
        on: {
          input: function($event) {
            if ($event.target.composing) return
            _vm.text = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn btn-secondary", on: { click: _vm.makeOp } },
      [_vm._v("Добавить заметку")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }